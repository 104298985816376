<template>
  <a-modal
    v-model:visible="showModal"
    :title="modalTitle"
    centered
    @ok="handleSubmit"
    okText="Submit"
    :footer="null"
  >
    <assigned-taskslist
      :loading="loadingAssignedTask"
      :assignedTaskList="assignedTaskList"
      @unassignTask="handleUnassignTask"
    ></assigned-taskslist>
  </a-modal>
  <a-table
    :columns="columns"
    :data-source="list"
    bordered
    v-if="list.length > 0"
    :scroll="{ y: '50vh', x: '900px' }"
  >
    <template #title>
      <a-typography-title :level="5">
        Labelers: &nbsp;
        <a-tag color="blue">{{ list.length }}</a-tag>
      </a-typography-title>
    </template>
    <template #bodyCell="{ column, record }">
      <template v-if="column.dataIndex === 'actions'">
        <div class="actions">
          <span>
            <a-button type="primary" @click="handleShowAssignedTasks(record)">
              Show Assigned Tasks
            </a-button>
          </span>
        </div>
      </template>
    </template>
  </a-table>

  <div v-else>
    <p>No Labeler</p>
  </div>
</template>
<script>
import LabelerServices from '../../../../services/labeler';
import AssignedTaskslist from './AssignedTasks.vue';

export default {
  props: ['list'],
  components: { AssignedTaskslist },
  inject: ['toast'],
  data() {
    return {
      columns: [
        {
          title: 'First Name',
          dataIndex: 'username',
        },
        {
          title: 'Last Name',
          dataIndex: 'lastname',
        },
        {
          title: 'Email',
          dataIndex: 'email',
        },
        {
          title: 'Position',
          dataIndex: 'position',
        },
        {
          title: '',
          dataIndex: 'actions',
        },
      ],
      showModal: false,
      loadingAssignedTask: false,
      assignedTaskList: [],
      selectedLabeler: null,
    };
  },

  computed: {
    modalTitle() {
      return `Tasks assigned to (${this.selectedLabeler?.username})`;
    },
  },

  methods: {
    handleSubmit() {
      this.showModal = false;
    },

    async handleShowAssignedTasks(labeler) {
      this.selectedLabeler = labeler;
      this.loadingAssignedTask = true;
      this.showModal = true;

      const [error, data] = await LabelerServices.getAssignedTask(labeler.id);
      if (error) {
        console.log({ error });
        return;
      }
      this.assignedTaskList = data;
      this.loadingAssignedTask = false;
    },

    async handleUnassignTask(taskId) {
      this.loadingAssignedTask = true;
      const payload = {
        taskId,
        labelers: [this.selectedLabeler.id],
      };

      const [error, data] = await LabelerServices.deleteAssignedTask(payload);

      if (error) {
        this.toast.error('Error occured while unassigning task!');
        return;
      }

      this.assignedTaskList = this.assignedTaskList.filter(
        (el) => el.id !== taskId
      );
      this.loadingAssignedTask = false;
    },
  },
};
</script>
