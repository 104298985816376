<template>
  <div class="p-4">
    <Labelers />
  </div>
</template>
<script>
import Labelers from './Labelers.vue';

export default {
  components: {
    Labelers,
  },
};
</script>
