<template>
  <div class="all-wrapper menu-side with-pattern">

    <form @submit.prevent="onSubmit">
      <!-- username -->
      <div class="form-group">
        <label for=""> Username:</label><input class="form-control" type="text" placeholder="Enter Username"
          v-model="v$.form.username.$model" />

        <!-- Error Message -->
        <div class="input-errors" v-for="(error, index) of v$.form.username.$errors" :key="index">
          <div class="error-msg">{{ error.$message }}</div>
        </div>
      </div>

      <!-- Email Row -->
      <div class="form-group">
        <label for=""> Email address</label><input class="form-control" placeholder="Enter email" type="email"
          v-model="v$.form.email.$model" />

        <!-- Error Message -->
        <div class="input-errors" v-for="(error, index) of v$.form.email.$errors" :key="index">
          <div class="error-msg">{{ error.$message }}</div>
        </div>
      </div>

      <!-- Password and Confirm Password Row -->
      <div class="form-group">
        <label for=""> Password</label><input class="form-control" placeholder="Password" type="password"
          v-model="v$.form.password.$model" />

        <!-- Error Message -->
        <div class="input-errors" v-for="(error, index) of v$.form.password.$errors" :key="index">
          <div class="error-msg">{{ error.$message }}</div>
        </div>
      </div>

      <!-- Submit Button -->
      <div class="text-right">
        <a-button @click.prevent="onCancel">
          Cancel
        </a-button>
        <a-button class="ml-2" type="primary" :disabled="!isPasswordSame || v$.form.$invalid" @click="onSubmit">
          Add
        </a-button>
      </div>
    </form>

  </div>
</template>
<script>
import useVuelidate from '@vuelidate/core';
import { required, email, minLength } from '@vuelidate/validators';
import { roles } from '../../../../config/roles-config';

export function validName(name) {
  let validNamePattern = new RegExp("^[a-zA-Z0-9]+(?:[-'\\s][a-zA-Z]+)*$");
  if (validNamePattern.test(name)) {
    return true;
  }
  return false;
}

export default {
  props: ['roles'],
  emits: ['addLabeler', 'cancel'],
  setup() {
    return { v$: useVuelidate() };
  },

  data() {
    return {
      isPasswordSame: true,
      form: {
        username: '',
        email: '',
        password: '',
        position: 'labeler',
        dateOfBirth: '',
        isValid: true,
      },
    };
  },

  validations() {
    return {
      form: {
        username: {
          required,
          name_validation: {
            $validator: validName,
            $message:
              'Invalid Username. Valid name only contain letters, dashes (-) and spaces',
          },
        },
        email: { required, email },
        password: { required, min: minLength(6) },
      },
    };
  },

  methods: {
    onSubmit() {
      const data = {
        email: this.form.email,
        username: this.form.username,
        lastname: this.form.username,
        position: '-',
        password: this.form.password,
        confirm_password: this.form.password,
        is_active: true,
        role: this.roles.find((r) => r.name === roles.labeler)?.id,
      };

      this.$emit('addLabeler', data);
      this.email = this.password = this.username = '';
    },
    onCancel() {
      this.$emit('cancel');
    },
  },
};
</script>

<style>
</style>
